@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  scroll-behavior: smooth;
}

.custom-img {
  background-image: url("./asssets/afro/Saro-Maria/ASR SM 02.jpg");
}

*::-webkit-scrollbar {
  @apply w-[5px] h-[5px];
}

*::-webkit-scrollbar-thumb {
  @apply rounded bg-slate-400;
}

*::-webkit-scrollbar-track {
  @apply !bg-transparent;
}

/* General styles */
p,
a,
svg {
  @apply text-muted;
}

a.btn {
  @apply opacity-100;
}

footer a {
  @apply text-sm transition-a;
}

footer a:hover {
  @apply ml-2 text-primary;
}

.heading {
  @apply text-3xl font-bold capitalize text-secondary dark:text-slate-700;
}

.sub-heading {
  @apply text-black text-sm uppercase w-fit bg-primary px-3 py-[3px] rounded-full mb-3 max-w-[200px];
}

.image {
  @apply group-hover:scale-125 transition-a;
}

.card {
  @apply p-4 bg-white border rounded-lg dark:bg-card-dark dark:border-dark;
}

.card-bordered {
  @apply border dark:border-dark;
}

.btn {
  @apply px-4 py-[0.35rem] rounded-lg transition-a sm:cursor-pointer !uppercase flex-shrink-0;
}

.btn-primary {
  @apply bg-primary text-white hover:bg-[#f7751e] shadow-md shadow-primary/60;
}

.btn-secondary {
  @apply bg-secondary text-white hover:bg-[#02293e];
}

.icon-box {
  @apply grid flex-shrink-0 rounded-full w-9 h-9 place-items-center hover:bg-slate-200 dark:hover:bg-hover-color-dark transition-a sm:cursor-pointer;
}

.border {
  @apply border-[#d2d2d2]/40;
}

.input {
  @apply border !border-slate-300 dark:!border-dark bg-transparent px-4 py-[0.35rem] outline-none focus:!border-primary;
}

.hide-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

/* Accordion */
.accordion.active {
  @apply text-primary;
}

.isOpen {
  @apply max-h-screen p-4;
}

