

.conten{
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    animation: animate 16s ease-in-out infinite;
    background-size: cover;
}
.outer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}
.details{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

}

@keyframes animate {
    0%,100%{
        background-image: url(../../../asssets/afro/Saro-Maria/9.jpg);
    }
    25%{
        background-image: url(../../../asssets/afro/Saro-Maria/11.jpg);
    }
    50%{
        background-image: url(../../../asssets/afro/Saro-Maria/12.jpg);
    }
    
}

